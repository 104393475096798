button.react-calendar__tile {
  border-radius: 10px;
  transition: background-color 300ms;
}
button.react-calendar__tile--now {
  color: white;
  background-color:#D6226B;
}
button.react-calendar__tile--active{
  background-color:#1087ff;
}

